<template>
    <div class="reserve-form">
        <div class="form-project">
            <p class="title">
                {{$t("reserve.reservesection")}}
            </p>
            <div class="project-info">
                <div class="info">
                    <span class="lable">预约项目：</span>
                    <p class="txt">{{projectObj.projectName}}</p>
                </div>
                <div class="info">
                    <span class="lable">预约时间：</span>
                    <p class="txt" v-show="projectObj.dateRangeDate">{{projectObj.dateRangeDate}}（{{projectObj.timeRangeStartTime}}-{{projectObj.timeRangeEndTime}}）</p>
                </div>
            </div>
        </div>
        <div class="form-project form-box">
            <p class="title">
                {{$t("reserve.reservebyInfo")}}
            </p>
            <div class="form-container">
                <div class="form-item" v-if="pageSetData.fieldName == 1">
                    <span class="label">{{$t("appointment.fullName")}}</span>
                     <div class="item-input">
                        <van-field v-model="fromData.userName" maxlength="10" :placeholder="$t('message.hintFullName')" />
                    </div>
                </div>
                 <div class="form-item" v-if="pageSetData.fieldTel == 1">
                    <span class="label">{{$t("appointment.phone")}}</span>
                     <div class="item-input">
                        <van-field v-model="fromData.userTel" @blur="!isPhoneNumber(fromData.userTel)&&fromData.userTel?tips(1):''" :maxlength="11" type="number" :placeholder="$t('message.hintPhone')" />
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldGender == 1">
                    <span class="label">{{$t("appointment.gender")}}</span>
                     <div class="item-input">
                        <van-radio-group v-model="fromData.userGender" direction="horizontal">
                            <van-radio name="男">男</van-radio>
                            <van-radio name="女">女</van-radio>
                        </van-radio-group>
                        <!-- <van-field v-model=""  :placeholder="$t('appointment.genderPlease')" /> -->
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldIdCode == 1">
                    <span class="label">{{$t("appointment.IDcode")}}</span>
                     <div class="item-input">
                        <van-field v-model="fromData.userIdCode" @blur="!isIDCard(fromData.userIdCode)&&fromData.userIdCode?tips(2):''" :maxlength="18" type="number" :placeholder="$t('message.hintIdCode')" />
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldAddress == 1">
                    <span class="label">{{$t("appointment.address")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userAddress"  :placeholder="$t('message.hintAddress')" />
                    </div>
                </div>
                
                <div class="form-item" v-if="pageSetData.fieldTitle == 1">
                    <span class="label">{{$t("appointment.positionalTitle")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userTitle"  :placeholder="$t('message.hintPositionalTitle')" />
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldDept == 1">
                    <span class="label">{{$t("appointment.department")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userDept"  :placeholder="$t('message.hintDepartment')" />
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldDuty == 1">
                    <span class="label">{{$t("appointment.duties")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userDuty"  :placeholder="$t('message.hintDuties')" />
                    </div>
                </div>

                <div class="form-item" v-if="pageSetData.fieldHosp == 1">
                    <span class="label">{{$t("appointment.hospital")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userHosp"  :placeholder="$t('message.hintHospital')" />
                    </div>
                </div>
                <div class="form-item" v-if="pageSetData.fieldEducation == 1">
                    <span class="label">{{$t("appointment.education")}}</span>
                    <div class="item-input">
                        <van-field v-model="fromData.userEducation"  :placeholder="$t('message.hinteDucation')" />
                    </div>
                </div>
                <div class="form-item" style="justify-content: space-between;" v-if="pageSetData.fieldImg == 1">
                    <span class="label">{{$t("appointment.photo")}}</span>
                    <div class="uploadImg">
                        <input type="file" accept="image/*" class="file" ref="files" @change="fileChange">
                        <!-- <van-uploader v-model="imgFiles" multiple :max-count="1" /> -->
                        <div class="upload">
                            <p class="icon" @click="upload" v-if="!fromData.userImg">
                                <img src="@/assets/img/upload-img-icon.png" />
                                <span >{{$t("message.hintPhoto")}}</span>
                            </p>
                            <img class="upload-img" v-else @click="upload" :src="fromData.userImg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="bottom-btn" >
            <p class="btn" @click="goReserveForm" :class="{'btnAcive':btndisabel}">{{$t("reserve.comfirmReserve")}}</p>
        </div>
    </div>
</template>

<script>
import { ReserveGetDates, ReserveGetInfo, reserveAdd } from "@/api/appointment";
import { isPhoneNumber, isIDCard } from "@/utils/vaildate";

export default {
    data () {
        return {
            isPhoneNumber,
            isIDCard,
            reserveId:this.$route.query.reserveId,
            projectId:this.$route.query.projectId,
            timeRangeId:this.$route.query.timeRangeId,
            dateRangeId:this.$route.query.dateRangeId,
            dateRangeDate:this.$route.query.dateRangeDate,
            projectObj:{},
            pageSetData:{},
            fromData:{
                reserveUserId:this.$route.query.reserveUserId,
                userGender:'',
                reserveId:this.$route.query.reserveId,
                userName:"",
                userTel:"",
                userTitle:"",
                userDept:"",
                userDuty:"",
                userHosp:"",
                userEducation:"",
                reserveUserId:"",
                userImg:"",
                userDatetime:"",
                userIdCode:""
            },
        }
    },
    computed: {
        btndisabel(){
            // if(!this.pageSetData.fieldName){
            //     return false;
            // }
            return (this.pageSetData.fieldName == 1?this.fromData.userName:true)
                && (this.pageSetData.fieldTel == 1?isPhoneNumber(this.fromData.userTel):true)
                && (this.pageSetData.fieldGender == 1?this.fromData.userGender:true)
                && (this.pageSetData.fieldIdCode == 1?isIDCard(this.fromData.userIdCode):true)
                && (this.pageSetData.fieldTitle == 1?this.fromData.userTitle:true)
                && (this.pageSetData.fieldDept == 1?this.fromData.userDept:true)
                && (this.pageSetData.fieldDuty == 1?this.fromData.userDuty:true)
                && (this.pageSetData.fieldHosp == 1?this.fromData.userHosp:true)
                && (this.pageSetData.fieldImg == 1?this.fromData.userImg:true)
        }
    },
    async created () {
        document.title = this.$t("reserve.name")
        const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t("message.loadingTxt")
        });
        await this.getProjectReserve();
        await this.getFormConfig()
        setTimeout(()=>{
            toast.clear()
        },500)
    },
    methods: {
        getProjectReserve(){
            ReserveGetDates({
                reserveId:this.reserveId,
                projectId:this.projectId,
            }).then(res => {
                if(res.data.errorCode == 0){
                    res.data.data.forEach(item => {
                        if(item.dateRangeDate == this.dateRangeDate&&this.projectId==item.projectId&&this.reserveId == item.reserveId&&this.timeRangeId == item.timeRangeId){
                            this.projectObj = item;
                            console.log(this.projectObj);
                        }
                    });
                }
            })
        },
        tips(type){
            this.$toast((type==1?this.$t("appointment.phone"):this.$t("appointment.IDcode"))+this.$t("message.formatError"))
        },
        getFormConfig(){
            ReserveGetInfo({
                reserveId:this.reserveId
            }).then(res =>{
                this.pageSetData = res.data.data;
            })
        },
        goReserveForm(){
            if(this.btndisabel){
                let datadd = {
                    ...this.fromData,
                    reserveId:this.reserveId,
                    projectId:this.projectId,
                    dateRangeId:this.dateRangeId,
                    reserveDate:this.dateRangeDate,
                    timeRangeId:this.timeRangeId,
                }
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                reserveAdd(datadd).then(res =>{
                    toast.clear();
                    if(res.data.errorCode == 0){
                        this.$router.push({
                            path:"/appointment/list"
                        })
                    }else{
                        this.$toast(res.data.errorMsg)
                    }
                })

            }
        },
        fileChange(e){
            const file = e.target.files[0];
            let fileType = file.type;
            let that = this;
            if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(fileType)) {
                this.$toast(this.$t("appointment.uploadcorrect"));
                return
            }
            if (file.size > 20 * 1024 * 1024) {
                this.$toast(this.$t("appointment.uploadSize"));
                return
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    var data = event.target.result;
                    const toast = this.$Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: this.$t("message.loadingTxt")
                    });
                    that.$uploadAxios.post('upload/base64-upload', {data:data,fileName:file.name}).then(async (res) => {
                        if(res.errorCode != 0){
                            toast.clear();
                            that.$toast(this.$t("appointment.uploadfails"));
                            return false
                        }
                        that.fromData.userImg = res.data;
                        that.$refs.files.value = '';
                        toast.clear();
                        // this.$emit('imgList', this.fileList)
                    })
                }
            } else {
                this.$toast(this.$t("appointment.noscript"));
            }
        },
        upload(){
            this.$refs.files.click();
        },
    }
}
</script>

<style lang="less" scoped>
.reserve-form{
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    background: #F5F6FA;
    padding-bottom: 2.8rem;
    position: relative;
    .form-project{
        margin-top: 0.4rem;
        padding:0.3rem 0.6rem;
        background: #fff;
        .title{
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #303133;
            line-height: 1.5rem;
            padding-left: 0.6rem;
            position: relative;
            &::after{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0,-50%);
                content: ' ';
                width: 0.16rem;
                height: 0.72rem;
                background: #3E8EFA;
                border-radius: 0.12rem;
            }
        }
        .project-info{
            .info{
                min-height: 1rem;
                display: flex;
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303133;
                line-height: 0.9rem;
                margin: 0.2rem 0;
                .lable{
                    flex: 0 0 auto;
                }
                .txt{
                    flex: 1;
                }
            }
        }
        .form-container{
            
            .form-item{
                min-height: 2rem;
                height: max-content;
                display: flex;
                align-items: center;
                
                border-bottom: 0.03rem solid #F5F6FA;
                &:last-child{
                    border: 0;
                }
                .label{
                    flex: 0 0 auto;
                    font-size: 0.64rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin-right: 0.2rem;
                    padding-left: 0.2rem;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: "*";
                        top: 50%;
                        transform: translate(0,-50%);
                        color: red;
                        left: -0.2rem;
                    }
                }
                .item-input{
                    flex: 1;
                    /deep/  .van-field{
                        width: 100%;
                        font-size: 0.64rem;
                        .van-field__control{
                            text-align: right;
                            color: #666666;
                            &::placeholder{
                                color: #999999;
                            }
                        }
                        
                    }
                    /deep/ .van-radio-group {
                        display: flex;
                        justify-content: flex-end;
                        .van-radio__label{
                            font-size: 0.56rem;
                        }
                        .van-radio__icon,.van-icon {
                            height: 0.6rem;
                            width: 0.6rem;
                            line-height: 0.6rem;
                            &::before{
                                font-size: 0.6rem;
                            }
                            // width: ;
                        }
                        .van-radio__icon  {
                            display: flex;
                            align-items: center;
                            margin-left: 0.2rem;
                        }
                    }
                }
                .uploadImg{
                    width: 3.3rem;
                    height: 3.3rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: .04rem solid #c8c9cc;
                    border-radius: .32rem;
                    margin: .6rem 0;
                    img{
                        max-width: 3.3rem;
                        max-height: 3.3rem;
                    }
                    .file{
                        display: none;
                        
                    }
                    .icon{
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        align-items: center;
                        img{
                            width: 1.04rem;
                            height: .94rem;
                            margin-bottom: .3rem;
                        }
                        span{
                            font-size: .5rem;
                            font-weight: 500;
                            color: #909399;
                        }
                    }
                }
            }
        }
    }
    .form-box{
        margin-bottom: 0.6rem;
    }
    .bottom-btn{
        position: fixed;
        left: 0;
        bottom: 0;
        background: #FFFFFF;
        width: 100%;
        height: 3rem;
        box-shadow: 0 -0.08rem 0.3rem 0 rgba(199,199,199,0.5);
        border-radius: 0.06rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            width: 11.2rem;
            height: 1.76rem;
            line-height: 1.76rem;
            border-radius: 1rem;
            text-align: center;
            background: #EBEEF5;
            color: #909399;
            font-size: 0.76rem;
        }
        .btnAcive{
            background: linear-gradient(180deg, #49BFFF 0%, #499FFF 100%);
            color: #fff;
        }
    }
}
</style>